<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">资源管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">平台课程</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <div title="资源提供者" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">资源提供者:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="审核状态" class="searchboxItem ci-full">
              <span class="itemLabel">审核状态:</span>
              <el-select
                v-model="auditState"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in auditStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            
          </div>
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="市场状态" class="searchboxItem ci-full">
              <span class="itemLabel">市场状态:</span>
              <el-select
                v-model="salesState"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in salesStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span class="itemLabel" style="min-width: 6rem">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </span>
            <div title="可见区域" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">可见区域:</span>
              <el-cascader
                clearable
                filterable
                v-model="areaId"
                :options="areatreeList"
                :props="propsarea"
                size="small"
              ></el-cascader>
            </div>

            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="课程名称"
                align="left"
                prop="courseName"
                show-overflow-tooltip
                minWidth="250"
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                minWidth="150"
                prop="trainTypeNamePath"
              >
                <template slot-scope="scope">{{
                  scope.row.trainTypeNamePath || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="行业类型"
                align="left"
                minWidth="200"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.industryNamePath || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="岗位类型"
                align="left"
                show-overflow-tooltip
                minWidth="200"
              >
                <template slot-scope="scope">{{
                  scope.row.postName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="职业/工种"
                align="left"
                show-overflow-tooltip
                width="300"
              >
                <template slot-scope="scope">{{
                  scope.row.occupationNamePath || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="培训等级"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.trainLevelName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="总学时"
                align="right"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.lessonNum || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="课时标准(分钟)"
                align="right"
                show-overflow-tooltip
                width="120"
              >
                <template slot-scope="scope">{{
                  scope.row.convertClassHour || "45分钟"
                }}</template>
              </el-table-column>
              <el-table-column
                label="总课时"
                align="right"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.totalClassHours || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="视频总时长"
                align="right"
                show-overflow-tooltip
                minWidth="120"
              >
                <template slot-scope="scope">{{
                  getTime(scope.row.kpointTotalDuration)
                }}</template>
              </el-table-column>
              <el-table-column
                label="资源提供者"
                align="left"
                show-overflow-tooltip
                minWidth="260"
              >
                <template slot-scope="scope">{{
                  scope.row.compName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="审核状态"
                align="center"
                show-overflow-tooltip
                width="100"
              >
                <template slot-scope="scope">{{
                  $setDictionary("AUDITSTATE", scope.row.auditState) ==
                  "审核未通过"
                    ? "未通过"
                    : $setDictionary("AUDITSTATE", scope.row.auditState) ==
                      "审核通过"
                    ? "已通过"
                    : $setDictionary("AUDITSTATE", scope.row.auditState)
                }}</template>
              </el-table-column>
              <el-table-column
                label="市场状态"
                align="left"
                show-overflow-tooltip
                prop="salesState"
                minWidth="120"
              >
                <template slot-scope="scope">
                  <el-switch
                    class="selStyle"
                    v-model="scope.row.showSaleState"
                    width="40"
                    :disabled="scope.row.showSaleState == false"
                    @change="getSwitch(scope.row.courseId)"
                  ></el-switch>
                  <span style="margin-left: 4px">{{
                    scope.row.showSaleState ? "上架" : "下架"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="400"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleLook(scope.row.courseId)"
                    >查看</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleSetVisibleArea(scope.row.courseId)"
                    >设置可见区域</el-button
                  >
                  <el-popover
                    placement="bottom"
                    trigger="click">
                    <p style="text-align: center;">选择下载格式</p>
                    <template style="text-align: center;">
                      <el-button size="mini" type="text" @click="ExportTimetableExcel(scope.row.courseId,'10')">excel</el-button>
                      <el-button size="mini" type="text" @click="ExportTimetablePdf(scope.row.courseId,'10')">pdf</el-button>
                    </template>
                    <el-button slot="reference" style="padding: 0px 5px" size="mini" type="text">导出课表</el-button>
                  </el-popover>
                  <el-popover
                    placement="bottom"
                    trigger="click">
                    <p style="text-align: center;">选择下载格式</p>
                    <el-checkbox-group v-model="timeTypeList">
                      <el-checkbox label="10">舍秒</el-checkbox>
                      <el-checkbox label="20">分秒</el-checkbox>
                      <el-checkbox label="30">分钟</el-checkbox>
                      <el-checkbox label="40">课时</el-checkbox>
                    </el-checkbox-group>
                    <div style="text-align: center;">
                      <el-button size="mini" type="text" @click="ExportTimetableExcelSH(scope.row.courseId,'20')">excel</el-button>
                      <el-button size="mini" type="text" @click="ExportTimetablePdfSH(scope.row.courseId,'20')">pdf</el-button>
                    </div>
                     <el-button slot="reference" type="text" style="padding: 0px 5px" size="mini">导出上海课表</el-button>
                  </el-popover>
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="uploadKpointOutline(scope.row)"
                    >下载大纲</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="
                      scope.row.auditState != '30'
                    "
                    @click="CoursePushBJ(scope.row.courseId)"
                    >课程推送(北京)</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="
                      scope.row.auditState != '30'
                    "
                    @click="CoursePushHN(scope.row.courseId)"
                    >课程推送(湖南)</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="
                      SetExcellentCourse(
                        scope.row.courseId,
                        scope.row.auditState
                      )
                    "
                    >设为精品课</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="setUpAccounting(scope.row.courseId)"
                    >设置核算</el-button
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="备注"
                align="left"
                prop="remark"
                show-overflow-tooltip
                minWidth="200"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="设置可见区域"
      :visible.sync="dialogVisible"
      width="50%"
      top="15%"
      :center="true"
      :before-close="doClose"
      :close-on-click-modal="false"
    >
      <span>可见区域：</span>
      <div style="padding-bottom: 1rem" class="seeArea">
        <el-radio-group v-model="radio">
          <el-radio label="1">全部区域可见</el-radio>
          <el-radio label="2">部分区域可见</el-radio>
          <el-radio label="3">指定机构可见</el-radio>
        </el-radio-group>
      </div>
      <div v-if="radio==2" class="df">
        <el-cascader
          class="flex1"
          :options="areatreeList"
          v-model="areaall"
          :props="propsareall"
          placeholder="请选择可见区域"
          clearable
          filterable
          @change="change"
        ></el-cascader>
        <el-button style="height: 40px" class="bgc-bv" @click="doall"
          >全部</el-button
        >
      </div>
      <div v-if="radio==3">
        <el-select
            style="width: 400px;margin-bottom: 10px;"
            size="small"
            v-model="itemCompanylist"
            remote
            value-key="compId"
            :remote-method="getCompanyList1"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
            multiple
            collapse-tags
            reserve-keyword
        >
          <el-option
              v-for="item in CompanyList1"
              :key="item.compId"
              :label="item.compName"
              :value="item"
          ></el-option>
        </el-select>
      <div>
          <template v-if="itemCompanylist.length>0">
            <el-tag
              style="margin: 5px;"
              v-for="(tag,index) in itemCompanylist"
              :key="tag.compId"
              @close="handleClose(tag,index)"
              :closable="true"
              >
              {{index+1}}.{{tag.compName}}
            </el-tag>
          </template>
          <template v-else>
              未选择机构
          </template>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button @click="doClose()" class="bgc-bv">取消</el-button>
        <el-button class="bgc-bv" @click="Sure()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "platformCourses",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      courseName: "",
      compId: "",
      itemCompanylist:[],//选中的机构
      CompanyList1:[],//搜索的机构
      radio: "1",
      areatreeList: [],
      auditStateList: [],
      auditState: "",
      areaall: "",
      salesState: "",
      propsareall: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
        multiple: true,
      },
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      dialogVisible: false,
      CompanyList: [],
      compType: "",
      areaId: "",
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
      isall: false,
      salesStateList: [
        {
          value: "20",
          label: "上架",
        },
        {
          value: "10",
          label: "下架",
        },
      ],
      // 导出上海课表的时间规则
      timeTypeList:['10','20','30','40']
    };
  },
  created() {
    this.getareatree();
    this.getauditStateList();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 设置核算
    setUpAccounting(courseId){
      this.$router.push({
        path:'/web/accountingIsSet',
        query:{courseId}
      })
    },
    // 删除指定机构
    handleClose(tag,index) {
      this.itemCompanylist.splice(index,1)
    },
    doall() {
      if (this.isall) {
        this.areaall = [];
        this.isall = false;
      } else {
        this.areaall = this.areatreeList.flatMap((el) => el.value);
        this.isall = true;
      }
    },
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    getSwitch(courseId) {
      this.$post("/biz/course/salesOff", {
        courseId,
      }).then((res) => {
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: "下架成功",
          });
          this.getData(-1);
        }
      });
    },
    /* 查看 */
    handleLook(courseId) {
      this.$router.push({
        path: "/web/detailResource",
        query: {
          courseId,
          stu: "setLook",
        },
      });
    },
    //导出课表pdf
    ExportTimetablePdf(courseId,type="10") {
      this.$post("run/course/export/platformcoursepdf", { courseId,type })
        .then((ret) => {
          if (ret.status == "0") {
            window.open(ret.data);
          }
        })
        .catch((err) => {
          return;
        });
    },
    //导出课表Excel
    ExportTimetableExcel(courseId,type='10') {
      this.$post("/run/course/export/platformcourse", { courseId,type })
        .then((ret) => {
          if (ret.status == "0") {
            window.open(ret.data.url);
          }
        })
        .catch((err) => {
          return;
        });
    },
    //导出上海课表pdf
    ExportTimetablePdfSH(courseId,type) {
      if(this.timeTypeList.length){
        this.$post("run/course/export/platformcoursepdf", { courseId,type,list: this.timeTypeList })
        .then((ret) => {
          if (ret.status == "0") {
            window.open(ret.data);
          }
        })
        .catch((err) => {
          return;
        });
      } else {
        this.$message({
          message: "请至少选择一项时长显示方式！",
          type: "warning",
        });
      }
    },
    //导出上海课表Excel
    ExportTimetableExcelSH(courseId,type) {
      if(this.timeTypeList.length){
        this.$post("/run/course/export/platformcourse", { courseId,type,list: this.timeTypeList })
        .then((ret) => {
          if (ret.status == "0") {
            window.open(ret.data.url);
          }
        })
        .catch((err) => {
          return;
        });
      } else {
        this.$message({
          message: "请至少选择一项时长显示方式！",
          type: "warning",
        });
      }
    },
    //课程推送（北京）
    CoursePushBJ(courseId) {
      this.$post("/run/course/doCourse", { courseId }).then((ret) => {
        if (ret.status == 0) {
          this.$message({
            message: "推送成功",
            type: "success",
          });
        }
      });
    },
    //课程推送（湖南）
    CoursePushHN(courseId) {
      this.$post("/run/course/doHNCourse", { courseId }).then((ret) => {
        if (ret.status == 0) {
          this.$message({
            message: "推送成功",
            type: "success",
          });
        }
      });
    },
    getauditStateList() {
      const auditStatelist = this.$setDictionary("AUDITSTATE", "list");
      const list = [{ label: "全部", value: "" }];
      for (const key in auditStatelist) {
        if (auditStatelist[key] == "审核通过") {
          list.push({
            value: key,
            label: "已通过",
          });
        } else if (auditStatelist[key] == "审核未通过") {
          list.push({
            value: key,
            label: "未通过",
          });
        } else {
          list.push({
            value: key,
            label: auditStatelist[key],
          });
        }
      }
      this.auditStateList = list;
    },
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getCompanyList1(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              let obj = {};
              let peon = [...res.data,...this.itemCompanylist].reduce((cur,next) => {
                  obj[next.compId] ? "" : obj[next.compId] = true && cur.push(next);
                  return cur;
              },[])
              this.CompanyList1 = peon || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        let obj = {};
        let peon = [...this.itemCompanylist].reduce((cur,next) => {
            obj[next.compId] ? "" : obj[next.compId] = true && cur.push(next);
            return cur;
        },[])
        this.CompanyList1 = peon;
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.courseName) {
        params.resourceName = this.courseName;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.auditState) {
        params.auditState = this.auditState;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }

      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      if (this.salesState) {
        params.salesState = this.salesState;
      }
      this.doFetch({
        url: "/run/course/platform/page",
        params,
        pageNum,
      });
    },
    /* 设置可见区域 */
    handleSetVisibleArea(courseId) {
      this.courseId = courseId;
      this.dialogVisible = true;
      this.$post("/run/course/visible/area", { courseId })
        .then((ret) => {
          if(ret.data.areaArr.length > 0||ret.data.compArr.length > 0){
            if (ret.data.areaArr.length > 0) {
              this.radio = "2";
              this.areaall = ret.data.areaArr;
            }
            if(ret.data.compArr.length > 0){
              this.radio = "3";
              this.itemCompanylist = ret.data.compArr;
              this.CompanyList1 = ret.data.compArr;
            }
          }else {
            this.radio = "1";
            this.areaall = [];
            this.itemCompanylist = [];
          }
        })
        .catch((err) => {
          return;
        });
    },
    Sure() {
      const paramr = {
        courseId: this.courseId,
      };
      if (this.radio == "1") {
        paramr.areaArr = [];
        paramr.compArr = [];
      } 
      if (this.radio == "2") {
        if(this.areaall.length===0){
          this.$message.warning('请选择可见区域！')
          return
        }
        paramr.areaArr = this.areaall;
        paramr.compArr = [];
      }
      if (this.radio == "3") {
        if(this.itemCompanylist.length===0){
          this.$message.warning('请选择指定机构！')
          return
        }
        paramr.areaArr = [];
        paramr.compArr = this.itemCompanylist.map(e=>e.compId);
      }
      this.$post("/run/course/setting/area", paramr)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              type: "success",
              message: "设置可见区域成功",
            });
            this.dialogVisible = false;
            this.radio = "1";
            this.areaall = [];
            this.itemCompanylist = [];
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    doClose() {
      this.dialogVisible = false;
      this.radio = "1";
      this.areaall = [];
      this.itemCompanylist = [];
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    /* 下载大纲 */
    uploadKpointOutline(row) {
      this.$post("/platform/resources/course/asyncDownloadCourseOutline", {
        courseId: row.courseId,
        courseName: row.courseName,
      })
        .then((res) => {
          if (res.status == "0") {
            let list = res.data;
            if (!this.downloadItems.includes(list.taskId)) {
              this.$store.dispatch("pushDownloadItems", list.taskId);
            } else {
              this.$message.warning(
                "[" + list.fileName + "]已经申请下载,请耐心等待"
              );
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
    //设为精品课
    SetExcellentCourse(courseId, auditState) {
      if (auditState == "30") {
        this.$post("/run/course/addMiniCourse", { courseId }).then((res) => {
          if (res.status == 0) {
            this.$message.success(res.message);
          }
        });
      } else {
        // let message = ''
        let   message = "该课程审核状态为【<span style='font-weight:600;color:red'>" + (this.$setDictionary('AUDITSTATE', auditState) ==
            '审核未通过'
            ? '未通过'
            : this.$setDictionary('AUDITSTATE', auditState)) +"</span>】,确定设为精品课?"
        this.$confirm(
          message,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            closeOnClickModal: false,
            dangerouslyUseHTMLString: true
          }
        )
          .then(() => {
            this.$post("/run/course/addMiniCourse", { courseId }).then(
              (res) => {
                if (res.status == 0) {
                  this.$message.success(res.message);
                }
              }
            );
          })
          .catch(() => {});
      }
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.seeArea {
  .el-radio-group {
    display: flex;
    flex-direction: column;
    .el-radio :last-child {
      margin-top: 1rem;
    }
  }
}

.searchbox {
  padding: 0;
}
.cascader {
  position: relative;
  width: 100%;
  z-index: 10;
}
</style>
